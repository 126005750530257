.main-content-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px;
  min-height: auto;
}
@media screen and (min-width: 768px) {
  .main-content-view {
    padding: 30px 32px 0;
  }
}
