.login-container {
  width: min(100% - 1rem, 35rem);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

.custom-container {
  width: min(100% - 1rem, 800px);

  margin-inline: auto;
}

.ant-layout {
  background: #fff !important;
}

.main-content-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px;
  min-height: auto;
  background: #fff !important;

  @media screen and (min-width: 768px) {
    padding: 30px 32px 0;
  }
}

.full-height {
  height: 100%;
}

.text-center {
  text-align: center;
}
